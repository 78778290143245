import PReact from "preact/compat";
import Icon, { CheckOutlined } from "@ant-design/icons";
import { hexToRGBA } from "~/utils";
import {
  CompanionCardType,
  CouponDisplayPosition,
  RetentionConfigType,
} from "~/types/shoppingCompanion";
import closeSvg from "~/assets/icons/coupon_close.svg?react";
import couponSvg from "~/assets/icons/coupon.svg?react";
import { useCouponCard } from "~/hooks/useCouponCard";
import "./index.scss";
import EmailCollection from "~/components/MessageList/MessageItem/EmailCollection";

interface BotAreaCouponProps {
  handleHide?: () => void;
  displayPosition: CouponDisplayPosition;
  showEmailInput?: boolean;
  showCode?: boolean;
}

const BotAreaCoupon: PReact.FC<BotAreaCouponProps> = ({
  handleHide: propHandleHide,
  displayPosition,
  showEmailInput = false,
  showCode = false,
}) => {
  const {
    brandColor,
    couponCompanion,
    currentCoupon,
    handleHide: hookHandleHide,
    isCopied,
    requiresEmailCollection,
    handleEmailSubmitBot,
    usageInstructions,
    handleCouponClickBot,
  } = useCouponCard(CompanionCardType.BotArea);

  // Return null if no coupon companion or no discount codes
  if (
    !couponCompanion ||
    !("discountCodes" in couponCompanion.popConfig.config) ||
    !couponCompanion.popConfig.config.discountCodes.length
  ) {
    return null;
  }

  if (showEmailInput) {
    return <EmailCollection usageInstruction={usageInstructions} onSubmit={handleEmailSubmitBot} />;
  }

  const handleClose = () => {
    propHandleHide?.();
    hookHandleHide();
  };

  const renderCloseButton = () => {
    const { retentionConfig } = couponCompanion;
    const showCloseButton = retentionConfig?.type === RetentionConfigType.AutoHide;

    if (!showCloseButton || displayPosition == CouponDisplayPosition.InBot) return null;

    return (
      <div className="close-btn" onClick={handleClose}>
        <Icon component={closeSvg} />
      </div>
    );
  };

  const renderCouponCode = () => {
    const buttonText =
      requiresEmailCollection && !showCode ? (
        "Claim Code"
      ) : isCopied ? (
        <CheckOutlined />
      ) : (
        "Copy Code"
      );

    return (
      <div
        className="coupon-content"
        style={{ backgroundColor: hexToRGBA(brandColor, 0.2) }}
        onClick={() => handleCouponClickBot(showCode)}
      >
        <div className="coupon-code">
          {requiresEmailCollection && !showCode ? "Coupon-X-Code" : currentCoupon?.code}
        </div>
        <div className="copy-btn" style={{ color: brandColor }}>
          {buttonText}
        </div>
      </div>
    );
  };

  const renderExpiryText = () => {
    if (!currentCoupon?.expiredTime) {
      return null;
    }
    const expiryText =
      currentCoupon?.expiredTime === "Never"
        ? "No Expiration"
        : `Valid until ${currentCoupon?.expiredTime}`;

    return (
      <div className="coupon-footer">
        <span className="expiry-text">{expiryText}</span>
      </div>
    );
  };

  return (
    <div className="bot-area-coupon">
      {renderCloseButton()}
      <div className="coupon-container">
        <div className="coupon-left">
          <div className="coupon-icon" style={{ color: brandColor }}>
            <Icon component={couponSvg} />
          </div>
        </div>
        <div className="coupon-right">
          {renderCouponCode()}
          {renderExpiryText()}
        </div>
      </div>
    </div>
  );
};

export default BotAreaCoupon;
