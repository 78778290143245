import { FunctionComponent } from "preact";
import { Product } from "~/types/product";
import { createMessage } from "~/api/chat";
import { useBotStore, useMessageStore } from "~/stores";
import { getConversationId } from "~/hooks/message/utils";
import { useRequestParams } from "~/hooks/useRequesParams";
import { CompanionCardType } from "~/types/shoppingCompanion";

import "./styles.scss";

export interface IProductBubbleCardProps {
  type?: CompanionCardType;
  products: Product[];
}

const ProductBubbleCard: FunctionComponent<IProductBubbleCardProps> = ({
  products,
  type = CompanionCardType.Featured,
}) => {
  const params = useRequestParams(true);
  const { toggleBotVisibilityInWidgetMode, getBotCode } = useBotStore();
  const { appendMessage } = useMessageStore();

  const renderCard = () => {
    if (type === CompanionCardType.Column) {
      return (
        <div className="product-bubble-content column-type">
          {products.slice(0, 3).map((product, index) => {
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: product.productVariants?.[0].priceInfo?.currencyCode ?? "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return (
              <div className="product-list-item" key={index}>
                <div className="product-cover-box">
                  <img
                    className="product-cover"
                    src={product.productVariants?.[0].productVariantImages[0]?.url}
                  />
                  <div className="hot tag">HOT</div>
                </div>
                <div className="product-info-box">
                  <div className="product-title">{product.name}</div>
                  <div className="price-box">
                    <div className="current-price">
                      {formatter.format(product.productVariants?.[0].priceInfo?.price ?? 0)}
                    </div>
                    {product.productVariants?.[0].priceInfo?.compareAtPrice && (
                      <div className="compared-price">
                        {formatter.format(
                          product.productVariants?.[0].priceInfo?.compareAtPrice ?? 0
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    if (type === CompanionCardType.Row) {
      return (
        <div className="product-bubble-content row-type">
          {products.slice(0, 3).map((product, index) => {
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: product.productVariants?.[0].priceInfo?.currencyCode ?? "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return (
              <div className="product-list-item" key={index}>
                <div className="product-cover-box">
                  <img
                    className="product-cover"
                    src={product.productVariants?.[0].productVariantImages[0]?.url}
                  />
                  <div className="tag hot">HOT</div>
                </div>
                <div>
                  <div className="product-title">{product.name}</div>
                  <div className="price-box">
                    <div className="current-price">
                      {formatter.format(product.productVariants?.[0].priceInfo?.price ?? 0)}
                    </div>
                    {product.productVariants?.[0].priceInfo?.compareAtPrice && (
                      <div className="compared-price">
                        {formatter.format(
                          product.productVariants?.[0].priceInfo?.compareAtPrice ?? 0
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    if (type === CompanionCardType.Featured) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: products[0].productVariants?.[0].priceInfo?.currencyCode ?? "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return (
        <div className="product-bubble-content featured-type">
          <div className="selected-product">
            <div className="product-cover-box">
              <img
                className="product-cover"
                src={products[0].productVariants?.[0].productVariantImages[0]?.url}
              />
              <div className="tag hot">HOT</div>
            </div>
            <div>
              <div className="product-title">{products[0].name}</div>
              <div className="price-box">
                <div className="current-price">
                  {formatter.format(products[0].productVariants?.[0].priceInfo?.price ?? 0)}
                </div>
                {products[0].productVariants?.[0].priceInfo?.compareAtPrice && (
                  <div className="compared-price">
                    {formatter.format(
                      products[0].productVariants?.[0].priceInfo?.compareAtPrice ?? 0
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="product-list">
            {products.map((product, index) => {
              return (
                <img
                  key={index}
                  className="product-thumbnail"
                  src={product.productVariants?.[0].productVariantImages[0]?.url}
                />
              );
            })}
          </div>
        </div>
      );
    }
  };

  if (products.length === 0) {
    return;
  }

  return (
    <div
      className="product-bubble"
      onClick={async () => {
        const botCode = getBotCode();
        const conversationId = await getConversationId(getBotCode());

        createMessage(conversationId, botCode, {
          ...params,
          content: "",
          messageFrom: "Bot",
          type: "companionProduct",
          conversationId,
          metadata: {
            resource: {
              companionProduct: {
                category: "Exclusive Discounts",
                summary: "Exclusive Discounted Products Recommended for You!",
                products,
                cardType: type,
              },
            },
          },
        }).then((res) => {
          appendMessage(res);
        });

        toggleBotVisibilityInWidgetMode?.();
      }}
    >
      <div className="product-bubble-title">Exclusive Discounts</div>
      <div className="product-bubble-desc">Exclusive Discounted Products Recommended for You!</div>
      {renderCard()}
    </div>
  );
};

export default ProductBubbleCard;
