import { useState, useEffect } from "preact/compat";
import {
  PopConfigType,
  ProductRecommendationType,
  RetentionConfigType,
  ShoppingCompanion,
  TriggerConfigType,
} from "~/types/shoppingCompanion";
import { useRequestParams } from "./useRequesParams";
import { getProductDetail, getRecommendProducts } from "~/api/product";
import { useBotStore, useShoppingCompanionStore } from "~/stores";
import { setCompanionDisplayTimestamp, shouldShowCompanion } from "~/utils/storage";

export const useShoppingCompanion = (companion?: ShoppingCompanion) => {
  // Use companion ID to create unique state keys
  const companionId = companion?.id;
  const [visibilityState, setVisibilityState] = useState<Record<string, boolean>>({});
  const isVisible = companionId ? visibilityState[companionId] || false : false;
  const tenantParams = useRequestParams();
  const { updateRecommendProducts } = useShoppingCompanionStore();
  const { integrationConfig } = useBotStore();

  // Reset states when companion changes
  useEffect(() => {
    if (!companionId) return;

    setVisibilityState((prev) => ({
      ...prev,
      [companionId]: false,
    }));
  }, [companionId]);

  const setIsVisible = (value: boolean) => {
    if (!companionId) return;
    setVisibilityState((prev) => ({
      ...prev,
      [companionId]: value,
    }));
  };

  // Trigger logic
  useEffect(() => {
    if (!companionId) return;

    let cleanup: (() => void) | undefined;
    const { triggerConfig } = companion;

    const handleTimeToStay = (staySeconds: number) => {
      const timeoutId = setTimeout(() => {
        if (shouldShowCompanion(companionId)) {
          setIsVisible(true);
          setCompanionDisplayTimestamp(companionId);
        }
      }, staySeconds * 1000);

      return () => clearTimeout(timeoutId);
    };

    const handleScrollPercentage = (targetPercentage: number) => {
      const handleScroll = () => {
        const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
        const currentScroll = window.scrollY;
        const currentPercentage = (currentScroll / scrollHeight) * 100;

        if (currentPercentage >= targetPercentage && shouldShowCompanion(companionId)) {
          setIsVisible(true);
          setCompanionDisplayTimestamp(companionId);
        }
      };

      window.addEventListener("scroll", handleScroll);
      // Check initial scroll position
      handleScroll();

      return () => window.removeEventListener("scroll", handleScroll);
    };

    const handlePageLeave = () => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === "hidden" && shouldShowCompanion(companionId)) {
          setIsVisible(true);
          setCompanionDisplayTimestamp(companionId);
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
    };

    // Apply appropriate trigger logic based on config type
    switch (triggerConfig.type) {
      case TriggerConfigType.TimeToStay:
        cleanup = handleTimeToStay(triggerConfig.config.staySeconds);
        break;
      case TriggerConfigType.ScrollPercentage:
        cleanup = handleScrollPercentage(triggerConfig.config.scrollPercentage);
        break;
      case TriggerConfigType.Leave:
        cleanup = handlePageLeave();
        break;
    }

    return () => {
      cleanup?.();
    };
  }, [companion?.triggerConfig, companionId]);

  // Retention logic
  useEffect(() => {
    if (!isVisible || !companionId) return;

    const { retentionConfig } = companion;

    if (retentionConfig.type === RetentionConfigType.AutoHide) {
      const timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, retentionConfig.config.hideAfterSeconds * 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [isVisible, companion?.retentionConfig, companionId]);

  useEffect(() => {
    if (companion?.popConfig.type === PopConfigType.ProductRecommendation) {
      if (
        companion.popConfig.config.recommendationType === ProductRecommendationType.SelectedProduct
      ) {
        getProductDetail({ ...tenantParams, productIds: companion.popConfig.config.productIds })
          .then((res) => {
            updateRecommendProducts(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (companion.popConfig.config.recommendationType === ProductRecommendationType.NewArrival) {
        getRecommendProducts(tenantParams, { productRecLimit: 3, productRecType: "new" })
          .then((res) => {
            updateRecommendProducts(res.products);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (companion.popConfig.config.recommendationType === ProductRecommendationType.BestSeller) {
        getRecommendProducts(tenantParams, { productRecLimit: 10, productRecType: "new" })
          .then((res) => {
            updateRecommendProducts(res.products);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (
        companion.popConfig.config.recommendationType === ProductRecommendationType.RelatedProducts
      ) {
        getRecommendProducts(tenantParams, {
          productRecLimit: 10,
          productRecType: "new",
          productRecSimProductId: integrationConfig.productId,
        })
          .then((res) => {
            updateRecommendProducts(res.products);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [isVisible, companion?.id]);

  const handleHide = () => {
    setIsVisible(false);
  };

  return {
    isVisible,
    handleHide,
  };
};
